<template>
  <!-- Navbar de escritorio (visible solo en pantallas grandes) -->
  <nav class="navbar desktop-navbar">
    <div class="logo">
      <router-link to="/">
        <img src="https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Logo.png?alt=media&token=5cd4d6d5-f7a9-419d-9500-f42fcbc77942" alt="Logo" class="logo-image" />
      </router-link>
    </div>

    <!-- Menú de navegación -->
    <ul class="nav-links">
      <li><router-link to="/">Inicio</router-link></li>
      <li><router-link to="/acerca-de">Acerca de</router-link></li>
      <li><router-link to="/Catalogo">Catalogo</router-link></li>
      <div class="promo-link">
        <li class="promo"><router-link to="/Promociones" class="promo-link">Promociones</router-link></li>
      </div>
      

      <li 
        @mouseenter="showCategories" 
        @mouseleave="hideCategories"
        class="dropdown-container"
      >
        <router-link to="/tienda">Tienda</router-link>
        <div class="dropdown-categories" v-if="categoriesVisible">
          <ul>
            <li v-for="(category, index) in categories" :key="index">
              <router-link :to="`/tienda/${category}`">{{ category }}</router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <!-- Componente de búsqueda -->
    <div class="search-container">
      <Search @cartUpdated="updateCartCount" />
    </div>

    <!-- Carrito con funcionalidad de hover -->
    <div class="cart-container" @mouseenter="showDropdown" @mouseleave="hideDropdown">
      <router-link to="/carrito" class="cart-button">
        <span class="cart-icon">🛒</span>
        <span class="cart-count">{{ cartItems.length }}</span>
      </router-link>

      <!-- Dropdown con la vista previa del carrito -->
      <div class="dropdown" v-if="dropdownVisible">
        <ul>
          <li v-for="(item, index) in cartItems" :key="index" class="dropdown-item">
            <img :src="item.Imagen" alt="Imagen del producto" class="dropdown-item-image" />
            <div class="dropdown-item-details">
              <span class="item-name">{{ item.Nombre }}</span>
              <span class="item-price">${{ item.Precio }}</span>
            </div>
          </li>
        </ul>
        <router-link to="/carrito" class="view-cart-button">Ver Carrito</router-link>
      </div>
    </div>
  </nav>

  <!-- Navbar de móvil (visible solo en pantallas pequeñas) -->
  <nav class="mobile-navbar">
    <div class="mobile-header">
      <!-- Ícono de menú hamburguesa -->
      <div class="hamburger" @click="toggleMobileMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- Logo centrado -->
      <div class="logo">
        <router-link to="/">
          <img src="https://firebasestorage.googleapis.com/v0/b/venta-plata-8deb5.appspot.com/o/Logo.png?alt=media&token=5cd4d6d5-f7a9-419d-9500-f42fcbc77942" alt="Logo" class="logo-image" />
        </router-link>
      </div>

      <!-- Carrito -->
      <div class="cart-container">
        <router-link to="/carrito" class="cart-button">
          <span class="cart-icon">🛒</span>
          <span class="cart-count">{{ cartItems.length }}</span>
        </router-link>
      </div>
    </div>

    <!-- Menú desplegable de navegación para móvil -->
    <ul :class="['nav-links', { 'mobile-menu': isMobileMenuOpen }]">
      <li><router-link to="/" @click.native="closeMobileMenu">Inicio</router-link></li>
      <li><router-link to="/acerca-de" @click.native="closeMobileMenu">Acerca de</router-link></li>
      <li><router-link to="/Catalogo">Catalogo</router-link></li>
      <li><router-link to="/Promociones" class="promo-link" @click.native="closeMobileMenu">Promociones</router-link></li>


      <li 
        @mouseenter="showCategories" 
        @mouseleave="hideCategories"
        class="dropdown-container"
      >
        <router-link to="/tienda" @click.native="closeMobileMenu">Tienda</router-link>
        <div class="dropdown-categories" v-if="categoriesVisible">
          <ul>
            <li v-for="(category, index) in categories" :key="index">
              <router-link :to="`/tienda/${category}`" @click.native="closeMobileMenu">{{ category }}</router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <!-- Componente de búsqueda -->
    <div class="search-container">
      <Search @cartUpdated="updateCartCount" />
    </div>
  </nav>
</template>




<script>
import Search from '/src/components/Search.vue';

export default {
  components: {
    Search,
  },
  data() {
    return {
      cartItems: [], // Almacena los productos del carrito
      dropdownVisible: false, // Controla la visibilidad del dropdown del carrito
      categoriesVisible: false, // Controla la visibilidad del dropdown de categorías
      isMobileMenuOpen: false, // Controla si el menú móvil está abierto o cerrado
      categories: ['Collares', 'Pulseras', 'Duos', 'Anillos'], // Ejemplo de categorías
      hideCategoriesTimeout: null, // Temporizador para ocultar categorías
      isMobile: window.innerWidth <= 768, // Inicializa isMobile
    };
  },
  mounted() {
    this.loadCartFromLocalStorage(); // Carga el carrito al montar el componente
    window.addEventListener('storage', this.updateCartCount); // Escucha cambios en localStorage
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.updateCartCount); // Limpia el evento al destruir el componente
    clearTimeout(this.hideCategoriesTimeout); // Limpia el temporizador si el componente se destruye
  },
  methods: {
    // Cargar el carrito desde localStorage
    loadCartFromLocalStorage() {
      const storedCart = JSON.parse(localStorage.getItem('cart'));
      if (storedCart) {
        this.cartItems = storedCart;
      }
    },
    // Actualiza el contador del carrito cuando hay cambios
    updateCartCount() {
      this.loadCartFromLocalStorage();
    },
    // Mostrar el desplegable de categorías
    showCategories() {
      clearTimeout(this.hideCategoriesTimeout); // Limpia el temporizador si se activa de nuevo
      this.categoriesVisible = true; // Muestra las categorías
    },
    // Ocultar el desplegable de categorías
    hideCategories() {
      this.hideCategoriesTimeout = setTimeout(() => {
        this.categoriesVisible = false; // Oculta las categorías después de un tiempo
      }, 200); // Retraso de 200 ms antes de ocultar
    },
    // Mostrar el desplegable del carrito
    showDropdown() {
      this.dropdownVisible = true; // Muestra el dropdown del carrito
    },
    // Ocultar el desplegable del carrito
    hideDropdown() {
      this.dropdownVisible = false; // Oculta el dropdown del carrito
    },
    // Alternar menú móvil
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768; // Actualiza el estado isMobile
      if (!this.isMobile) {
        this.isMobileMenuOpen = false; // Cierra el menú al salir de móvil
      }
    },
  },
};
</script>


<style scoped>
/* Estilo para los enlaces de Promociones */
.promo-link {
  color: red;  /* Cambiar el color del texto a rojo */
  text-decoration: none;  /* Eliminar subrayado si existe */
}

/* Si quieres cambiar el color cuando el enlace esté en hover, puedes añadir lo siguiente */
.promo-link:hover {
  color: darkred;  /* Color más oscuro al pasar el cursor */
}

/* Ocultar la navbar móvil en pantallas grandes */
.mobile-navbar {
  display: none;
}

/* Mostrar la navbar de escritorio en pantallas grandes */
.desktop-navbar {
  display: flex;
}

.dropdown-container {
  position: relative;
}

.dropdown-categories {
  position: absolute;
  background-color: #252525; /* Blanco */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
  border-radius: 4px;
  width: 150px;
}

.dropdown-categories ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #252525;
}

.dropdown-categories li {
  padding: 10px 15px;
  color: #1A1A1A; /* Negro profundo */
}

.dropdown-categories li:hover {
  background-color: #1A1A1A; /* Oro suave */
  
  
}

.navbar {
  position: fixed;
  justify-content: space-between;
  top: 0px;
  padding-top: 20px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #252525;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links li {
  list-style: none;
  
}

.nav-links a {
  text-decoration: none;
  color: #C0C0C0; /* Plata metálico */
  font-weight: 500;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #D6CDAF; /* Oro suave */

}
.nav-links promo{
  
  color: #ff0040;

}

.logo-image {
  height: 60px;
  width: 280px;
  margin-left: 15px;
  aspect-ratio: 280/60;
  
}

.cart-container {
  position: relative;
  width: 5.8%;
  right: 5rem;
 
}

.cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  color: #FFFFFF; /* Texto blanco */
  font-size: 1.5em;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  background-color: transparent; /* Azul marino */
}

.cart-button:hover {
  transform: translateY(-2px);
  background-color: transparent; /* Verde suave */
}



.cart-count {
  font-weight: normal;
  font-size: 0.8em;
  color: #D6CDAF; /* Oro suave */
  
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1a1a1a; /* Blanco */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  
  
}

.dropdown ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.dropdown li {
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #C0C0C0; /* Negro profundo */
}

.dropdown-item-image {
  width: 50px;
  height: 30px;
  object-fit: cover;
  border-radius: 5px;
  
}

.view-cart-button {
  color: #D6CDAF; /* Oro suave */
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  
}

@media (max-width: 1024px) {

  .dropdown-container {
  position: relative;

}

.dropdown-categories {
  position: absolute;
  left: 7.5rem;
  background-color: #252525; /* Blanco */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
  border-radius: 4px;
  width: 150px;
}

.dropdown-categories ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #252525;
}

.dropdown-categories li {
  
  padding: 10px 15px;
  color: #1A1A1A; /* Negro profundo */
}

.dropdown-categories li:hover {
  background-color: #1A1A1A; /* Oro suave */
  
  
}




  .desktop-navbar {
    display: none;
  }
  
  .mobile-navbar {
    display: block;
    width: 100%;
    background-color: #252525;
    position: fixed; /* Hace que la navbar esté fija en la parte superior */
    top: 0;
   
    left: 0;
    z-index: 1000; /* Asegura que la navbar esté encima de otros elementos */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Añade una sombra para diferenciarla */
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .hamburger {
    order: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .hamburger span {
    height: 3px;
    width: 25px;
    background-color: #C0C0C0; /* Plata metálico */
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .logo {
    order: 2;
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .logo-image {
    max-height: 45px;
    width: auto;
   
  }

  .cart-container {
    order: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 5px;
    
  }

  .cart-button {
    font-size: 1.2em;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Ajusta la posición del menú cuando esté desplegado */
    left: 0;
    background-color: #252525; /* Fondo del menú */
    width: 100%;
    padding: 10px;
    z-index: 1000; /* Mantiene el menú encima de otros elementos */
    color: #FFFFFF;
  }

  .nav-links.mobile-menu {
    display: flex;
    width: 100%;
    margin-left: 0;
    top: 60px; /* Para que el menú quede alineado con la navbar */
  }

  .search-container {
    width: 100%;
    padding: 10px;
     /* Asegura que la barra de búsqueda esté debajo de la navbar */
  }



}

</style>
